import { useLocation } from "@reach/router";
import { Link, graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import queryString from "query-string";
import React from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { Helmet } from "react-helmet";
import { RiQuestionnaireFill } from "react-icons/ri";
import slugify from "slugify";
import Layout from "../components/layout";
import Navigation from "../components/navigation";
import { Search } from "../components/search";

const SupportCentrePage = () => {
	const data = useStaticQuery(graphql`
		query {
			site {
				siteMetadata {
					siteUrl
				}
			}
			img1: wpMediaItem(title: { eq: "Google-image" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}

			heroImg: wpMediaItem(title: { eq: "Hero-Homepage" }) {
				altText
				mediaDetails {
					height
					width
				}
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			allWpCategory(filter: { name: { ne: "Uncategorized" } }) {
				edges {
					node {
						name
						description
					}
				}
			}
			wpSeoPage(title: { eq: "Support Centre" }) {
				contentForHeadTag {
					content
				}
				seo {
					metaDesc
					title
					twitterImage {
						altText
						mediaDetails {
							height
							width
						}
						link
						gatsbyImage(
							width: 1920
							quality: 75
							formats: [WEBP]
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
					opengraphTitle
					opengraphDescription
				}
			}
			allWpHowToGuide {
				edges {
					node {
						slug
						categories {
							nodes {
								name
								description
							}
						}
						tags {
							nodes {
								name
							}
						}
						howToGuideFields {
							description
							featured
						}
						title
					}
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;
	const { search } = useLocation();
	const qs = queryString.parse(search);
	const selectedCollectionIds = qs.c?.split(",").filter((c) => !!c) || [];
	const selectedCollectionIdsMap = {};
	const searchTerm = qs.s;
	const guides = data.allWpHowToGuide.edges.map(({ node }) => node);

	const filterBySearchTerm = (guide) => {
		if (searchTerm) {
			const guideTagList = [];
			const guideCategoryList = [];

			guide.tags.nodes.forEach((tag) => {
				guideTagList.push(tag.name.toLowerCase());
			});
			guide.categories.nodes.forEach((cat) => {
				guideCategoryList.push(cat.name.toLowerCase());
			});

			return (
				guide.title.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0 ||
				guideTagList.includes(searchTerm.toLowerCase()) ||
				guideCategoryList.includes(searchTerm.toLowerCase())
			);
		}
	};
	const categories = [];

	guides.forEach((guide) =>
		guide.categories.nodes.forEach((cat) => {
			if (!categories.includes(cat.name)) {
				categories.push(cat.name);
			}
		})
	);

	const filteredGuides = guides.filter(filterBySearchTerm);
	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Support Centre",
				item: {
					url: `${siteUrl}/support-centre`,
					id: `${siteUrl}/support-centre`,
				},
			},
		],
	};

	return (
		<>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			{/* <LocalBusinessSchema /> */}
			<GatsbySeo
				title={data.wpSeoPage.seo.title}
				description={data.wpSeoPage.seo.metaDesc}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/support-centre`,
					title: `${data.wpSeoPage.seo.opengraphTitle}`,
					description: `${data.wpSeoPage.seo.opengraphDescription}`,
					images: [
						{
							url: `${data.wpSeoPage.seo.twitterImage.link}`,
							width: `${data.wpSeoPage.seo.twitterImage.mediaDetails.width}`,
							height: `${data.wpSeoPage.seo.twitterImage.mediaDetails.height}`,
							alt: `${data.wpSeoPage.seo.twitterImage.altText}`,
						},
					],
				}}
			/>

			<Layout>
				<Navigation bg="primary" />
				<section className="bg-primary py-5 py-lg-10 position-relative">
					<Container>
						<Row className="justify-content-center">
							<Col lg={8} className="text-center">
								<h1 className="pt-3 mb-3 ssp-bold text-uppercase  text-white hero-title">
									Support <span className="text-secondary">Centre</span>
								</h1>
								<p className="text-white pb-5 ssp-semibold">
									How to guides, useful links and information about your website
									from RJM Digital
								</p>
								<Search />
							</Col>
						</Row>
					</Container>
				</section>
				<section className="py-7 py-md-8 py-lg-9">
					<Container>
						<Row>
							<Col>
								<h2 className="text-primary pb-5 ssp-bold">HOW TO GUIDES</h2>
							</Col>
						</Row>
						{filteredGuides.length > 0 && (
							<Row className="gy-5 ">
								{filteredGuides.map((guide) => (
									<Col lg={6}>
										<div className="d-flex align-items-center">
											<RiQuestionnaireFill className="display-2 text-primary me-3" />
											<Link
												to={`/how-to-guides/${guide.slug}`}
												className="fs-2 black-link ssp-semibold"
											>
												{guide.title}
											</Link>
										</div>
									</Col>
								))}
							</Row>
						)}
						{filteredGuides.length < 1 && (
							<Row className="gy-5 ">
								{guides
									.filter((item) => item.howToGuideFields.featured === true)
									.map((guide) => (
										<Col lg={6}>
											<div className="d-flex align-items-center">
												<RiQuestionnaireFill className="display-2 text-primary me-3" />
												<Link
													to={`/how-to-guides/${guide.slug}`}
													className="fs-2 black-link ssp-semibold"
												>
													{guide.title}
												</Link>
											</div>
										</Col>
									))}
							</Row>
						)}
					</Container>
				</section>
				<section className="pb-lg-7 pb-5">
					<Container>
						<Row className="g-5">
							{data.allWpCategory.edges
								.filter((item) => categories.includes(item.node.name))
								.map((cat) => (
									<Col lg={6}>
										<div
											style={{ boxShadow: "0px 3px 8px #00000029" }}
											className=" p-4"
										>
											<h3 className="text-primary ssp-bold">{cat.node.name}</h3>
											<p>{cat.node.description}</p>

											<Button
												className="mt-3 fs-5 w-100 w-md-auto  py-2 px-4 white-link"
												variant="light-blue"
												as={Link}
												to={`/how-to-guides/categories/${slugify(
													cat.node.name.toLowerCase()
												)}`}
											>
												View page
											</Button>
										</div>
									</Col>
								))}
						</Row>
					</Container>
				</section>
				<section className="py-5 py-lg-7 bg-med-grey">
					<Container>
						<Row>
							<Col>
								<h2 className="text-primary pb-5 ssp-bold">BROWSE RESOURCES</h2>
							</Col>
						</Row>
						<Row className="g-5">
							<Col lg={4}>
								<a href="mailto:support@rjm.digital" className="black-link">
									<h3 className="mb-3">Raise a support ticket</h3>
									<GatsbyImage
										image={data.img1.gatsbyImage}
										alt={data.img1.altText}
										className="w-100"
									/>
								</a>
							</Col>
							<Col lg={4}>
								<a
									href="mailto:hello@rjm.digital?subject=Upgrade my package"
									className="black-link"
								>
									<h3 className="mb-3">Upgrade my package</h3>
									<GatsbyImage
										image={data.img1.gatsbyImage}
										alt={data.img1.altText}
										className="w-100"
									/>
								</a>
							</Col>
							<Col lg={4}>
								<a
									target="_blank"
									rel="noreferrer"
									href="https://invoice.zoho.com/portal/rjmdigital"
									className="black-link"
								>
									<h3 className="mb-3">View my invoices</h3>
									<GatsbyImage
										image={data.img1.gatsbyImage}
										alt={data.img1.altText}
										className="w-100"
									/>
								</a>
							</Col>
						</Row>
					</Container>
				</section>
			</Layout>
		</>
	);
};

export default SupportCentrePage;
